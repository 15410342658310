
.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0rem;
    background:radial-gradient(circle at 30% -100%, rgba(27, 120, 222, 1) 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    height: 20px;
  }
  
  .gpt3__navbar-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .gpt3__navbar-links_logo {
    margin-left: 5rem;
  }
  
  .gpt3__navbar-links_logo img {
    width: 150px;
    height: 100px;
  }
  
  .gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
  }
  
  .gpt3__navbar-links_container a {
    text-decoration: none;
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
  }
  
  .gpt3__navbar-menu {
    display: none; /* Hide the dropdown initially */
  }
  
  .gpt3__navbar-menu svg {
    cursor: pointer;
  }
  
  .gpt3__navbar-menu_container {
    display: none; /* Hide the dropdown container initially */
  }
  
  @media screen and (max-width: 700px) {
    .gpt3__navbar {
      position: fixed; /* Fix the navbar at the top */
      z-index: 999; /* Ensure it's above other content */
      width:700px ;
    }
  
    .gpt3__navbar-links_container {
      display: none; /* Hide the links on mobile */
    }
  
    .gpt3__navbar-menu {
      display: flex; /* Display the dropdown button on mobile */
    }
  
    .gpt3__navbar-menu_container {
      display: flex;
      flex-direction: column;
      background: white;
      text-align: center;
      position: absolute;
      right: 20rem; /* Adjust position as needed */
      top: 6rem; /* Adjust position as needed */
      min-width: 210px;
      border-radius: 5px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
  
    .gpt3__navbar-menu_container p {
      margin: 1rem 0;
    }
  
    .gpt3__navbar-menu_container a {
      text-decoration: none;
      color: rgb(176, 119, 13);
      font-size: 18px;
      line-height: 25px;
    }
  
    .gpt3__navbar-menu_container-links-sign {
      display: block;
      text-decoration: none;
    }
  }
  