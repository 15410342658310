.footer {
    padding: 30px 0;
    color: aliceblue;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-logo {
    display: flex; /* Add flex display to align items */
    align-items: center; /* Center items vertically */
  }
  
  .footer-logo img {
    width: 100px;
    height: auto;
    margin-right: 20px; /* Add margin to separate image and text */
  }
  
  .footer-links {
    margin-left: 50px;
  }
  
  .footer-links h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .footer-links p {
    margin: 5px 0;
  }
  
  .footer-links a {
    color: rgb(223, 140, 71);
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-copyright {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-copyright a{
     color: rgba(27, 120, 222, 1);
    text-decoration: none;
  }